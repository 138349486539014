<template>
    <section v-show="products.length > 0">
        <div ref="columnist" class="row columnist-wall">
            <div v-for="product in products" class="col-6 col-md-3 pb-3 columnist-column">
                <a :href="product.onlineStoreUrl" target="_blank" rel="noopener" class="card hover-shadow text-decoration-none">
                    <img :src="product.images[0].src" :alt="product.title" class="card-img-top">
                    <div class="card-footer text-center small">
                        {{ product.title }}
                    </div>
                </a>
            </div>
        </div>
        <div class="text-center">
            <a href="https://shop.pronouns.page" target="_blank" rel="noopener" class="btn btn-primary">
                <Icon v="shopping-bag" />
                shop.pronouns.page
            </a>
        </div>
    </section>
</template>

<script>
import Columnist from 'avris-columnist';
import { shallowRef } from 'vue';

import { random } from '~/src/helpers.ts';

export default {
    setup() {
        return {
            // use shallowRef because the Spotify API returns some proxy itself which does not work well when
            // Vue wraps it into another proxy
            products: shallowRef([]),
        };
    },
    async mounted() {
        if (!this.$config.public.shopifyStorefrontToken) {
            return;
        }

        await this.$loadScript('shopify', 'https://sdks.shopifycdn.com/js-buy-sdk/v2/latest/index.umd.min.js');

        if (typeof window.ShopifyBuy === 'undefined') {
            return;
        }

        const client = window.ShopifyBuy.buildClient({
            domain: 'shop.pronouns.page',
            storefrontAccessToken: this.$config.public.shopifyStorefrontToken,
        });

        client.product.fetchAll().then((products) => {
            this.products = products
                .filter((product) => product.availableForSale)
                .sort(() => 0.5 - random())
                .slice(0, 4);

            const columnist = new Columnist(this.$el.querySelector('.columnist-wall'));
            columnist.start();
        });
    },
};
</script>

<style lang="scss" scoped>
section {
    overflow: hidden;
}
</style>
